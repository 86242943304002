/* global  */ // tUrl等のグローバル変数を宣言する

import 'core-js/features/number/is-nan' // SwiperのIE対応
import 'custom-event-polyfill' // SwiperのIE対応
import { Autoplay, Controller, EffectFade, Navigation, Pagination, Swiper } from 'swiper'
Swiper.use([Autoplay, Controller, EffectFade, Navigation, Pagination]) // Swiper宣言
// その他、Swiperで使う機能があったら上に追加で宣言する
// A11y, Autoplay, Controller, EffectCoverflow, EffectCube, EffectFade, EffectFlip, HashNavigation, History, Keyboard, Lazy, Mousewheel, Navigation, Pagination, Parallax, Scrollbar, Thumbs, Virtual, Zoom,
var Swiper01 = new Swiper('.sec-opinions__swiper-container-pc', {
  slidesPerView: 3.5,
  spaceBetween: 10,
  speed: 500, // アニメ速度
  loop: true, //最後の画像までいったらループする
  //左右のナビゲーションをつける場合
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
})

var SwiperSP = new Swiper('.sec-opinions__swiper-container-sp', {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 10,
  speed: 500, // アニメ速度
  freeMode: true,
  loop: true, //最後の画像までいったらループする
  //左右のナビゲーションをつける場合
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
})

var Swiper02 = new Swiper('.sec-scene__loc-swiper-sp', {
  effect: 'slide', // slide,fade,cube,coverflow
  freeMode: true,
  speed: 5000, // アニメ速度
  spaceBetween: 20,
  slidesPerView: 2.3,
  loop: true,
  autoplay: {
    delay: 0, // 待機時間
    disableOnInteraction: false, // 操作されたらオート解除
  },
})

$(function () {
  $('.sec-question__contents-title').click(function () {
    $(this).toggleClass('selected')
    $(this).next().slideToggle()
  })
})

if ($('#sec-scene').length) {
  // headerの色切り替え
  const postion = $('#sec-scene').offset()
  jQuery(window).on('scroll', function () {
    if (jQuery(this).scrollTop() > postion.top) {
      jQuery('.header').addClass('change-color')
    } else {
      jQuery('.header').removeClass('change-color')
    }
  })

  // 要素のフェードアウト
  jQuery(window).on('scroll', function () {
    if (jQuery(this).scrollTop() > postion.top - 180) {
      jQuery('.sec-mv__bg').addClass('mv-change-color')
    } else {
      jQuery('.sec-mv__bg').removeClass('mv-change-color')
    }
  })
}

// 画面に入った監視対象に.animatedを付加
// Intersection Observer
if (document.getElementsByClassName('js-in')[0]) {
  intObs()
}
function intObs() {
  const intObsOption = {
    // root: document.querySelector('#obsArea'), // 監視領域 省略でビューポート
    rootMargin: '-20px 0px', // 指定px,%分判定領域を増減する -50% 0pxで画面中央の走査線になる
    threshold: 0, // 発火判定の閾値 0.1=10%以上交差で発火 0で接触次第発火
  }
  const observer = new IntersectionObserver(intObsCallback, intObsOption)
  const intObsItems = document.getElementsByClassName('js-in')
  for (let index = 0; index < intObsItems.length; index++) {
    const intObsItem = intObsItems[index]
    observer.observe(intObsItem) // 監視対象に加える
  }
  function intObsCallback(entries) {
    for (let index = 0; index < entries.length; index++) {
      const entry = entries[index]
      if (entry.isIntersecting) {
        const entryItem = entry.target
        entryItem.classList.add('active')
        observer.unobserve(entryItem) // 監視対象から外す
      }
    }
  }
}
